import { useCallback, useState } from 'react';
import { getFeed, getFeedByUsername, getPreview } from '../services/feed';
import { session } from '../signals/session';
import { AxiosError } from 'axios';
import { logout } from '../utils/session';
import { deletePost, likePost, unlikePost } from '../services/post';
import { useNavigate } from 'react-router-dom';
import { getSuggestions } from '../services/user';
import { getPlansByCreatorId } from '../services/creator';

const useFeed = (isMyProfile?: boolean) => {
  const navigate = useNavigate();

  const [suggestions, setSuggestions] = useState<Profile[]>([]);

  const [feed, setFeed] = useState<Feed>({
    page: 0,
    totalPages: 0,
    totalPosts: 0,
    totalImages: 0,
    totalVideos: 0,
    posts: [],
    profile: {
      avatar: '',
      cover: '',
      about: '',
      fullname: '',
      username: '',
      userId: '',
    },
    subscribed: false
  });
  const [plan, setPlan] = useState<Plan>({
    id: '',
    creatorId: '',
    value: 0,
    interval: 0,
  });
  const [isFeedLoading, setIsFeedLoading] = useState(false);
  const [isFeedNextPageLoading, setIsFeedNextPageLoading] = useState(false);

  const handleApiError = useCallback(
    (error: AxiosError) => {
      switch (error.response?.status) {
        case 404: {
          if (!isMyProfile) {
            navigate('/error');
          }
          break;
        }
        case 401:
          logout();
          break;
      }
    },
    [isMyProfile, navigate]
  );

  const loadSuggestions = useCallback(async () => {
    try {
      const suggestionList = await getSuggestions();
      setSuggestions(suggestionList);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const loadPage = useCallback(
    async (username?: string) => {
      try {
        setIsFeedLoading(true);
        const feed: Feed = username
          ? await getFeedByUsername(username)
          : await getFeed();

        if (username) {
          const plans = await getPlansByCreatorId(feed.creatorId);
          if (plans.length) {
            setPlan(plans[0]);
          }
        }

        setFeed(feed);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsFeedLoading(false);
      }
    },
    [handleApiError]
  );

  const loadPreviewPage = useCallback(
    async () => {
      try {
        setIsFeedLoading(true);
        const previewPosts: Post[] = await getPreview();

        const previewFeed: Feed = {
          page: 1,
          totalPages: 1,
          totalPosts: 0,
          totalImages: 0,
          totalVideos: 0,
          posts: previewPosts,
          subscribed: false
        }

        setFeed(previewFeed);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsFeedLoading(false);
      }
    },
    [handleApiError]
  );

  const onPost = useCallback(async () => {
    try {
      setIsFeedLoading(true);
      const feed = await getFeedByUsername(session.value.username);
      setFeed(feed);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFeedLoading(false);
    }
  }, []);

  const onDeletePost = useCallback(
    async (postIndex: number) => {
      try {
        let newPosts = [
          ...feed.posts.slice(0, postIndex),
          ...feed.posts.slice(postIndex + 1),
        ];

        setFeed((preview) => {
          const newFeed: Feed = {
            ...preview,
            posts: newPosts,
          };
          return newFeed;
        });

        const post = feed.posts[postIndex];

        await deletePost(post.postId);
      } catch (error) {
        console.log(error);
      }
    },
    [feed.posts]
  );

  const fetchFeedNextPage = useCallback(
    async (username?: string) => {
      try {
        setIsFeedNextPageLoading(true);

        const nextFeedPage: Feed = username
          ? await getFeedByUsername(username, feed.page + 1)
          : await getFeed(feed.page + 1);

        // const nextFeedPage = await getFeedByUsername(
        //   session.value.username,
        //   feed.page + 1
        // );

        setFeed((preview) => {
          const newFeed: Feed = {
            ...nextFeedPage,
            posts: [...preview.posts, ...nextFeedPage.posts],
          };
          return newFeed;
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsFeedNextPageLoading(false);
      }
    },
    [feed.page]
  );

  const onLikePost = useCallback(
    async (postIndex: number) => {
      try {
        const post = feed.posts[postIndex];

        let likes = post.likes;
        let liked = post.liked;

        if (liked) {
          unlikePost(post.postId);
          liked = false;
          likes -= 1;
        } else {
          likePost(post.postId);
          liked = true;
          likes += 1;
        }

        setFeed((preview) => {
          const posts = preview.posts;
          posts[postIndex].likes = likes;
          posts[postIndex].liked = liked;
          const newFeed: Feed = {
            ...preview,
            posts,
          };
          return newFeed;
        });
      } catch (error) {
        console.log(error);
      }
    },
    [feed.posts]
  );

  return {
    isFeedLoading,
    isFeedNextPageLoading,
    feed,
    plan,
    suggestions,
    loadSuggestions,
    loadPage,
    loadPreviewPage,
    onPost,
    onDeletePost,
    onLikePost,
    fetchFeedNextPage,
  };
};

export default useFeed;
