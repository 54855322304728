import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import './main.scss';

import * as serviceWorker from './serviceWorker';

import DefaultNewsfeed from './pages/Newsfeed/DefaultNewsfeed';
import PopularNewsFeed from './pages/Newsfeed/PopularNewsFeed';
import Account from './pages/Account';
import Payment from './pages/Payment';
import Payout from './pages/Payout';
import Login from './pages/Login';
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import UserPage from './pages/Userpage';
import VerifyAccount from './pages/VerifyAccount';
import ResetPassword from './pages/ResetPassword';
import MyProfile from './pages/MyProfile';
import MyFollowers from './pages/MyFollowers';
import Subscriptions from './pages/Subscriptions';
import Subscribers from './pages/Subscribers';
import ErrorPage from './pages/ErrorPage';
import Following from './pages/Following';

import ProtectedRoute from './components/Utility/ProtectedRoute';

import PayoutAccount from './pages/PayoutAccount';
import Creator from './pages/Creator';
import Menu from './components/Menu';
import Settings from './pages/Settings';
import ChangePassword from './pages/ChangePassword';
import CreatorSettings from './pages/CreatorSettings';
import PublicRoute from './components/Utility/PublicRoute';

import ImageGalleryView from './pages/ImageGallery';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <PopularNewsFeed  />
            </ProtectedRoute>
          }
        />
        <Route
          path="/feed"
          element={
            <ProtectedRoute>
              <DefaultNewsfeed />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/myprofile"
          element={
            <ProtectedRoute>
              <MyProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/myfollowers"
          element={
            <ProtectedRoute>
              <MyFollowers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/following"
          element={
            <ProtectedRoute>
              <Following />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <ProtectedRoute>
              <Subscriptions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscribers"
          element={
            <ProtectedRoute>
              <Subscribers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/menu"
          element={
            <ProtectedRoute>
              <Menu />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payout"
          element={
            <ProtectedRoute>
              <Payout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payout-account"
          element={
            <ProtectedRoute>
              <PayoutAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account-information"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/creator-settings"
          element={
            <ProtectedRoute>
              <CreatorSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:username"
          element={
            <ProtectedRoute>
              <UserPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/creator"
          element={
            <ProtectedRoute>
              <Creator />
            </ProtectedRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot"
          element={
            <PublicRoute>
              <Forgot />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/image-viewer"
          element={
            <PublicRoute>
              <ImageGalleryView />
            </PublicRoute>
          }
        />
        <Route path="/verify-account" Component={VerifyAccount} />
        <Route path="/error" Component={ErrorPage} />
      </Routes>
    </BrowserRouter>
  );
};

const container = document.getElementById('root');

let root = createRoot(container!);

root.render(<App />);

serviceWorker.register();
