import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { profile } from '../signals/profile';
import { effect } from '@preact/signals';
import { scrolling } from '../signals/feedScrollPage';
import { isMobile } from '../utils/data';

const Appfooter: React.FC = () => {
  const [isScrollingPage, setIsScrollingPage] = useState(false);

  effect(() => {
    if (!isMobile()) return;

    if (!isScrollingPage && scrolling.value) {
      setIsScrollingPage(true);
    } else if (isScrollingPage && !scrolling.value) {
      setIsScrollingPage(false);
    }
  });

  return (
    <div
      className="app-footer border-0 shadow-lg"
      style={{ opacity: isScrollingPage ? 0.5 : 1, paddingBottom: 40 }}
    >
      <Link to={profile.value.creatorId ? '/myprofile' : '/'}>
        <i className="feather-home text-warning"></i>
      </Link>
      <Link to="/menu" className='mt-3'>
        <figure className="avatar">
          <i className="feather-menu text-warning" />
        </figure>
      </Link>
    </div>
  );
};

export default Appfooter;
