import React, { useState } from 'react';
import { effect } from '@preact/signals';
import { scrolling } from '../../signals/feedScrollPage';
import { Link } from 'react-router-dom';

type Props = {
  selectedTabIndex: number;
};

const FeedTab: React.FC<Props> = ({ selectedTabIndex }) => {
  const [isScrollingPage, setIsScrollingPage] = useState(false);

  effect(() => {
    if (!isScrollingPage && scrolling.value) {
      setIsScrollingPage(true);
    } else if (isScrollingPage && !scrolling.value) {
      setIsScrollingPage(false);
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        position: 'fixed',
        zIndex: 100,
        marginTop: isScrollingPage ? 10 : 80,
        width: '100%',
        alignSelf: 'center',
      }}
    >
      <Link
        style={{
          width: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          padding: 5,
          marginRight: 5,
          borderRadius: 10,
          cursor: 'pointer',
          border: `${selectedTabIndex !== 0 ? '3px solid #f4f4f4' : '0px'}`,
        }}
        to="/"
        className={`shadow-xss ${
          selectedTabIndex === 0 ? 'bg-warning' : 'bg-white'
        }`}
      >
        <i
          className={`${
            selectedTabIndex === 0 ? 'text-white' : 'text-grey-600'
          } feather-trending-up font-sm me-2`}
        ></i>{' '}
        <span
          className={`fw-700 font-xsssss ${
            selectedTabIndex === 0 ? 'text-white' : 'text-grey-600'
          }`}
        >
          Popular
        </span>
      </Link>
      <Link
        style={{
          borderRadius: 10,
          width: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f2f2f2',
          padding: 5,
          cursor: 'pointer',
          border: `${selectedTabIndex !== 1 ? '3px solid #f4f4f4' : '0px'}`,
        }}
        className={`shadow-xss ${
          selectedTabIndex === 1 ? 'bg-warning' : 'bg-white'
        }`}
        to="/feed"
      >
        <i
          className={`${
            selectedTabIndex === 1 ? 'text-white' : 'text-grey-600'
          } feather-unlock font-sm me-2`}
        ></i>{' '}
        <span
          className={`fw-700 font-xsssss ${
            selectedTabIndex === 1 ? 'text-white' : 'text-grey-600'
          }`}
        >
          Feed
        </span>
      </Link>
    </div>
  );
};

export default FeedTab;
