import axios, { AxiosResponse } from 'axios';
import { session } from '../signals/session';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const savePlan = (value: number) => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/plan`,
        { value },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPlan = () => {
  return new Promise<AxiosResponse>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(`${REACT_APP_API_URL}/plan`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      resolve(resp);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPlansByCreatorId = (creatorId: string) => {
  return new Promise<Plan[]>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(
        `${REACT_APP_API_URL}/creator/${creatorId}/plan`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const subscribe = (creatorId: string, planId: string) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.post(
        `${REACT_APP_API_URL}/subscription`,
        { creatorId, planId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp.data.url);
    } catch (error) {
      reject(error);
    }
  });
}

export const ledger = () => {
  return new Promise<Ledger>(async (resolve, reject) => {
    try {
      const { token } = session.value;

      const resp = await axios.get(
        `${REACT_APP_API_URL}/ledger`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      resolve(resp.data);
    } catch (error) {
      reject(error);
    }
  })
}