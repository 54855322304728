import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import Appfooter from '../components/Appfooter';
// import { formatCurrency } from '../utils/data';
import LeftMenu from '../components/LeftMenu';
import { Spinner } from 'react-bootstrap';
import { ledger } from '../services/creator';
import { formatCurrency } from '../utils/data';

const Payout = () => {
  const [loading, setLoading] = useState(false);

  const [payout, setPayout] = useState<Ledger>({
    availableBalance: 0,
    totalBalance: 0,
  });

  const [history] = useState<
    {
      date: string;
      amount: number;
      status: number;
    }[]
  >([
    {
      date: '10/01/2024',
      amount: 2000.0,
      status: 1,
    },
    {
      date: '10/02/2024',
      amount: 4000.0,
      status: 1,
    },
  ]);

  // const navigate = usseNavigate();

  useEffect(() => {
    const getLedger = async () => {
      setLoading(true);
      try {
        const resp = await ledger();
        setPayout(resp);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    getLedger();
  }, []);

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                {loading ? (
                  <div
                    style={{
                      height: 500,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      className="text-warning mt-3"
                    />
                  </div>
                ) : (
                  <>
                    <div className="card-body p-lg-5 w-100 border-0 mb-5">
                      <h4 className="font-xs text-black fw-600 mb-0 mt-2">
                        Pagamento
                      </h4>
                      <div className="d-flex flex-column justify-content-between mt-4">
                        <div>
                          <h5 className="font-xsss text-grey-600 fw-500 mt-2">
                            Total a receber
                          </h5>
                          <p className="font-xl fw-600">{`${formatCurrency(
                            payout.totalBalance / 100
                          )}`}</p>
                        </div>
                        <div>
                          <h5 className="font-xsss text-grey-600 fw-500 mt-2">
                            Total disponível para retirada
                          </h5>
                          <p
                            className="font-xxl fw-600"
                            style={{ color: '#00A36C' }}
                          >{`${formatCurrency(
                            payout.availableBalance / 100
                          )}`}</p>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <p className="font-xsss mt-1">
                            Ao solicitar a retirada total disponível, iremos
                            analisar a solicitação e enviaremos o pagamento em
                            até 24 hrs. Caso não tenha configurado a conta de
                            recebimento ainda,{' '}
                            <span
                              className="text-warning fw-700"
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                              onClick={() => false}
                            >
                              configure sua conta de recebimento aqui.
                            </span>
                          </p>
                          <button
                            onClick={() => {
                              console.log('false');
                            }}
                            className="rounded-3 bg-current mb-2 mt-2 p-3 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-white d-block border-0 bg-warning"
                          >
                            solicitar retirada
                          </button>
                          {/* <button
                            className="font-xsss text-warning fw-500 text-decoration-underline border-0 bg-transparent"
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => false}
                          >
                            configurar conta de recebimento
                          </button> */}
                        </div>
                      </div>
                      <h5 className="font-xss text-black fw-600 mt-4 mb-3">
                        Histórico
                      </h5>
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row justify-content-between mb-2">
                          <span className="font-xss fw-600 text-left">
                            Data
                          </span>
                          <span className="font-xss fw-600 text-center">
                            Total
                          </span>
                          <span className="font-xss fw-600 text-right">
                            Status
                          </span>
                        </div>
                        <div style={{ overflow: 'auto', maxHeight: 200 }}>
                          {history.map((item) => {
                            return (
                              <div className="d-flex flex-row justify-content-between">
                                <p className="font-xsss text-left">
                                  {item.date}
                                </p>
                                <p className="font-xsss text-center me-4">
                                  {formatCurrency(item.amount)}
                                </p>
                                <p className="font-xsss text-right">
                                  Sucesso
                                  {/* {item.status ? 'Sucesso' : 'Failure'} */}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </>
  );
};

export default Payout;
