import axios from 'axios';
import { session } from '../signals/session';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const getFeed = (page?: number) => {
  return new Promise<Feed>((resolve, reject) => {
    const { userId, token } = session.value;

    axios
      .get(`${REACT_APP_API_URL}/feed`, {
        data: { targetUserId: userId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getPreview = (page?: number) => {
  return new Promise<Post[]>((resolve, reject) => {
    const { userId, token } = session.value;

    axios
      .get(`${REACT_APP_API_URL}/preview`, {
        data: { targetUserId: userId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getFeedByUsername = (username: string, page?: number) => {
  return new Promise<Feed>((resolve, reject) => {
    const { token } = session.value;

    axios
      .get(`${REACT_APP_API_URL}/feed/${username}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getFollowers = () => {
  return new Promise<
    {
      id: string;
      userId: string;
      avatar: string;
      username: string;
    }[]
  >((resolve, reject) => {
    const { token } = session.value;

    axios
      .get(`${REACT_APP_API_URL}/follower`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data.followers);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
