import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Appfooter from '../../components/Appfooter';
import FeedScrollPage from '../../components/Utility/FeedScrollPage';
import Feed from '../../components/Utility/Feed';
import LeftMenu from '../../components/LeftMenu';
import Suggestions from '../../components/Suggestions';
import useFeed from '../../hooks/useFeed';
import BecomeCreatorBanner from '../../components/BecomeCreatorBanner';
import { profile } from '../../signals/profile';
import FeedTab from '../../components/FeedTab';
import { isMobile } from '../../utils/data';

const PopularNewsfeed = () => {
  const {
    feed,
    suggestions,
    isFeedLoading,
    isFeedNextPageLoading,
    loadPreviewPage,
    loadSuggestions,
    onLikePost,
  } = useFeed();

  useEffect(() => {
    loadSuggestions();
    loadPreviewPage();
  }, [loadPreviewPage, loadSuggestions]);

  return (
    <div>
      <Header />
      {isMobile() && <FeedTab selectedTabIndex={0} />}
      <LeftMenu />
      <FeedScrollPage
        loading={isFeedLoading}
        footerLoading={isFeedNextPageLoading}
        // onReachOutBottom={fetchFeedNextPage}
        feed={feed}
      >
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row" style={{ marginTop: isMobile() ? 50 : 0 }}>
              <div className="col-xl-8 mb-5">
                <Feed
                  isLoading={isFeedLoading}
                  isNextPageLoading={isFeedNextPageLoading}
                  feed={feed}
                  onLikePost={onLikePost}
                />
              </div>
              <div className="col-xl-4 suggestions">
                <Suggestions suggestionList={suggestions} />
                {!profile.value.creatorId && <BecomeCreatorBanner />}
              </div>
            </div>
          </div>
        </div>
      </FeedScrollPage>
      <Appfooter />
    </div>
  );
};

export default PopularNewsfeed;
